import WebServiceRequest from "../Api/WebServiceRequest";

class GetEmailList extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("NotificationEmailReserver/GetList");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class CreateNewEmailNotifier extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("NotificationEmailReserver/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class UpdateAnEmailNotifier extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("NotificationEmailReserver/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class DeleteAnEmailNotifier extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("NotificationEmailReserver/Delete");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export {
  GetEmailList,
  CreateNewEmailNotifier,
  UpdateAnEmailNotifier,
  DeleteAnEmailNotifier,
};
