<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col cols="12">
        <b-form class="w-100" @submit.prevent>
          <b-form-group label="Choose your desired form" label-for="formPicker">
            <v-select
              @search="handleSearch"
              id="formPicker"
              :filterable="false"
              v-if="formsList"
              v-model="selectedForm"
              :options="formsList"
              :reduce="(form) => form.formId"
              label="name"
              placeholder="Choose your form"
            >
              <template #no-options>No forms available</template>
            </v-select>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import vSelect from "vue-select";
import { BOverlay, BRow, BCol, BForm, BFormGroup } from "bootstrap-vue";
import { GetAllForms } from "@/libs/Api/Form";
export default {
  emits: ["getSelectedFormId"],
  mounted() {
    this.getAllForms();
  },
  props: {
    defaultSelectedFormTitle: {
      type: [String, Number],
      required: false,
    },
  },
  watch: {
    selectedForm: {
      handler(val) {
        this.$emit("getSelectedFormId", val);
      },
    },
  },
  data() {
    return {
      isLoading: false,
      searchCommand: null,
      formsList: null,
      timeout: null,
      selectedForm: null,
      count: 20,
      pageNumber: 1,
    };
  },
  methods: {
    async getAllForms() {
      try {
        this.isLoading = true;
        let reqParams = {
          count: this.count,
          pageNumber: this.pageNumber,
        };
        if (this.searchCommand) {
          reqParams["searchCommand"] = this.searchCommand;
        }
        let _this = this;
        let getAllForms = new GetAllForms(_this);
        getAllForms.setRequestParam(reqParams);
        await getAllForms.fetch((response) => {
          if (response.isSuccess) {
            _this.formsList = response.data.forms;
            _this.totalCount = response.data.formsCount;
            if (_this.defaultSelectedFormTitle) {
              let selectedForm = _this.formsList.find(
                (item) => item.name == _this.defaultSelectedFormTitle
              );
              if (selectedForm) {
                _this.selectedForm = selectedForm.formId;
              }
            }
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleSearch(searchValue, loadingFn) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.searchCommand = searchValue;
        loadingFn(true);
        await this.getAllForms();
        loadingFn(false);
      }, 300);
    },
  },
  components: {
    vSelect,
    BOverlay,
    BRow,
    BCol,
    BForm,
    BFormGroup,
  },
};
</script>
