<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-2 w-100 d-flex align-items-center">
        <h1>Configured Email List</h1>
      </div>
      <b-row class="mb-2">
        <b-col cols="12">
          <b-button variant="success" v-b-modal.modal-email-create>
            Add New Configuration
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <form-picker @getSelectedFormId="setFormFilter"></form-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            class="position-relative"
            :items="emailsList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="No items available"
          >
            <template #cell(isActive)="data">
              <b-badge
                :variant="data.item.isActive ? 'success' : 'danger'"
                class="font-weight-normal"
              >
                {{ data.item.isActive ? "Active" : "Disable" }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center" style="gap: 1rem">
                <b-button
                  class="m-0 p-0"
                  variant="transparent"
                  size="sm"
                  @click="renderEditModal(data.item)"
                >
                  <feather-icon
                    size="20"
                    icon="EditIcon"
                    class="text-primary"
                  />
                </b-button>
                <b-button
                  class="m-0 p-0"
                  variant="transparent"
                  size="sm"
                  @click="renderDeleteModal(data.item)"
                >
                  <feather-icon
                    size="20"
                    icon="TrashIcon"
                    class="text-danger"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2 d-flex align-items-center justify-content-center">
            <b-pagination
              v-model="emailListFilters.pageNumber"
              :total-rows="totalCount"
              :per-page="emailListFilters.count"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- Delete Modal Start -->
    <b-modal
      id="modal-delete"
      centered
      ok-variant="danger"
      title="Delete a configuration"
      ok-title="Delete"
      cancelTitle="Dismiss"
      @ok="sendDeleteEmailRequest"
    >
      <template v-if="tempEmail">
        <span>
          Are you sure you want to delete "{{ tempEmail.objectTitle }}" Config?
        </span>
      </template>
    </b-modal>
    <!-- Delete Modal End -->

    <!-- Edit Modal Start -->
    <b-modal
      id="modal-email-update"
      centered
      ok-variant="success"
      title="Edit a confiuration"
      ok-title="Update"
      @ok="updateAnEmail"
      cancelTitle="Dismiss"
    >
      <b-container fluid>
        <b-form-row v-if="tempEmail">
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-form-group label="Email Address" label-for="email">
                <b-form-input
                  id="email"
                  placeholder="info@ariaexport.com"
                  v-model="tempEmail.email"
                />
              </b-form-group>
              <form-picker
                :defaultSelectedFormTitle="tempEmail.objectTitle"
                @getSelectedFormId="setTempEmailFormId"
              ></form-picker>
              <b-form-checkbox
                id="isActive"
                v-model="tempEmail.isActive"
                name="isActive"
                class="mb-2"
              >
                Activation Status
              </b-form-checkbox>
            </b-form>
          </b-col>
        </b-form-row>
      </b-container>
    </b-modal>
    <!-- Edit Modal End -->

    <!-- Create New Email Modal Start  -->
    <b-modal
      no-close-on-backdrop
      id="modal-email-create"
      centered
      ok-variant="success"
      title="Create New Configuration"
      ok-title="Create"
      @ok="createNewEmailNotifier"
      cancelTitle="Dismiss"
    >
      <b-container fluid>
        <b-form-row>
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-form-group label="Email Address" label-for="email">
                <b-form-input
                  id="email"
                  placeholder="info@ariaexport.com"
                  v-model="newEmailDetails.email"
                />
              </b-form-group>
              <form-picker @getSelectedFormId="setSelectedFormId"></form-picker>
              <b-form-checkbox
                id="isActive"
                v-model="newEmailDetails.isActive"
                name="isActive"
                class="mb-2"
              >
                Activation Status
              </b-form-checkbox>
            </b-form>
          </b-col>
        </b-form-row>
      </b-container>
    </b-modal>
    <!-- Create New Email Modal End -->
  </b-overlay>
</template>

<script>
export default {
  title: "Email Configuration List",
  mounted() {
    this.getAllEmails();
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllEmails();
      },
    },
  },
  data() {
    return {
      isLoading: false,
      emailsList: null,
      totalCount: null,
      tempEmail: null,
      emailListFilters: {
        email: null,
        type: null,
        isActive: null,
        objectId: null,
        pageNumber: 1,
        count: 10,
      },
      newEmailDetails: {
        id: 0,
        email: null,
        type: 1, // is for Form
        objectId: null,
        isActive: true,
      },
      myTableColumns: [
        { key: "id", label: "Configuration ID" },
        { key: "email", label: "Email Address" },
        { key: "objectTitle", label: "Configuration Title" },
        { key: "isActive", label: "Activation Status" },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  methods: {
    async getAllEmails() {
      try {
        this.isLoading = true;
        let _this = this;
        let getEmailList = new GetEmailList(_this);
        getEmailList.setRequestParamDataObj(_this.handleGetAllEmailsFilters());
        await getEmailList.fetch((res) => {
          _this.emailsList = res.data;
          _this.totalCount = res.total;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createNewEmailNotifier() {
      try {
        this.isLoading = true;
        let _this = this;
        let createNewEmailNotifier = new CreateNewEmailNotifier(_this);
        createNewEmailNotifier.setRequestParamDataObj(_this.newEmailDetails);
        await createNewEmailNotifier.fetch((res) => {
          if (res.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Successful",
                icon: "CheckIcon",
                variant: "success",
                text: "Configuration Created Successfully",
              },
            });
            _this.getAllEmails();
          } else {
            _this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something Went Wrong!",
                icon: "XIcon",
                variant: "danger",
                text: res.errors || "Please Try Again",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setSelectedFormId(formId) {
      this.newEmailDetails.objectId = formId;
    },
    setTempEmailFormId(formId) {
      this.tempEmail.objectId = formId;
    },
    handleGetAllEmailsFilters() {
      let payload = {
        pageNumber: this.emailListFilters.pageNumber,
        count: this.emailListFilters.count,
      };
      if (this.emailListFilters.email) {
        payload["email"] = this.emailListFilters.email;
      }
      if (this.emailListFilters.type) {
        payload["type"] = this.emailListFilters.type;
      }
      if (this.emailListFilters.isActive) {
        payload["isActive"] = this.emailListFilters.isActive;
      }
      if (this.emailListFilters.objectId) {
        payload["objectId"] = this.emailListFilters.objectId;
      }
      return payload;
    },
    setFormFilter(formId) {
      this.emailListFilters.objectId = formId;
      this.getAllEmails();
    },
    async sendDeleteEmailRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteAnEmailNotifier = new DeleteAnEmailNotifier(_this);
        deleteAnEmailNotifier.setRequestParam({ id: _this.tempEmail.id });
        await deleteAnEmailNotifier.fetch((res) => {
          if (res.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Successful",
                icon: "Trash2Icon",
                variant: "success",
                text: "Configuration Deleted Successfully",
              },
            });
            _this.getAllEmails();
          } else {
            _this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something Went Wrong",
                icon: "XIcon",
                variant: "danger",
                text: res.errors || "Please Try Again",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderEditModal(tempEmail) {
      this.tempEmail = JSON.parse(JSON.stringify(tempEmail));
      this.$bvModal.show("modal-email-update");
    },
    async updateAnEmail() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateAnEmailNotifier = new UpdateAnEmailNotifier(_this);
        updateAnEmailNotifier.setRequestParamDataObj(_this.tempEmail);
        await updateAnEmailNotifier.fetch((res) => {
          if (res.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Successful",
                icon: "CheckIcon",
                variant: "success",
                text: "Configuration Updated Successfully",
              },
            });
            _this.tempEmail = null;
            _this.getAllEmails();
          } else {
            _this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something Went Wrong",
                icon: "XIcon",
                variant: "danger",
                text: res.errors || "Please Try Again",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderDeleteModal(tempEmail) {
      this.tempEmail = JSON.parse(JSON.stringify(tempEmail));
      this.$bvModal.show("modal-delete");
    },
  },
  computed: {
    pageNumber() {
      return this.emailListFilters.pageNumber;
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
    BFormCheckbox,
    BBadge,
    FormPicker,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import FormPicker from "@/views/components/Forms/FormPicker.vue";
import {
  GetEmailList,
  CreateNewEmailNotifier,
  UpdateAnEmailNotifier,
  DeleteAnEmailNotifier,
} from "@/libs/Api/NotificationEmailReserver";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
</script>
